:root {
  --primary: #EDEDED;
  --secondary: #444444;
  --dark: #171717;
  --red: #DA0037;
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dark);
}

* {
  box-sizing: border-box;
  color: var(--primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
